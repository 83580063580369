
.topbar-profile {
    position: relative;
    width: 40px;
    height: 40px;
  
    button {
      width: 100%;
      height: 100%;
      padding: 0;
  
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  
  .p-menubar-root-list {
    min-width: 15rem;
  }
  
  ::-webkit-scrollbar {
    scrollbar-width: thin;
    width: 5px !important;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
  
  .loader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }
  
  .visible {
    display: block;
  }
  
  .hidden {
    display: none;
  }
  