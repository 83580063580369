@media screen and (min-width: $breakpoint) {
  .layout-container {
    &.layout-reveal {
      .layout-topbar {
        .topbar-menubutton {
          display: none;
        }
      }

      .layout-sidebar {
        ::-webkit-scrollbar {
            display: none;
          }
          ::-webkit-scrollbar-track {
            display: none;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            display: none;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            display: none;
          }
        height: 100%;
        top: 0;
        transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1);
        transform: translateX(-12.75rem);
        z-index: 999;

        .app-logo {
          display: flex;
          justify-content: center;
          gap: 1rem;

          .layout-sidebar-anchor {
            display: none;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            border: 2px solid var(--logo-color);
            background-color: transparent;
            transition: background-color var(--transition-duration),
              transform 0.3s;
          }
        }

        .layout-menu {
          .layout-root-menuitem {
            > .layout-menuitem-root-text {
              > span {
                margin-right: auto;
              }

              > .layout-menuitem-root-icon {
                display: block;
                margin-right: 0.125rem;
              }
            }
          }

          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            a {
              padding: 0.75rem 1rem;

              .layout-menuitem-text {
                order: 1;
                margin-right: auto;
              }

              .layout-submenu-toggler {
                order: 2;
                display: none;
                margin-right: 0.5rem;
              }

              .layout-menuitem-icon {
                order: 3;
                margin-right: 0;
                font-size: 1.25rem;
                width: auto;
              }
            }
          }
        }
      }

      &.layout-reveal {
        .layout-content-wrapper {
          margin-left: 4.25rem;
          transition: margin-left 0.3s cubic-bezier(0, 0, 0.2, 1);
        }
      }

      &.layout-reveal-active {
        .layout-sidebar {
          transform: translateX(0);

          .layout-sidebar-anchor {
            display: block;
            animation: px-fadein 0.15s linear;
          }

          .layout-menu {
            .layout-root-menuitem {
              > .layout-menuitem-root-text {
                > .layout-menuitem-root-icon {
                  display: none;
                }
              }
            }

            ul {
              a {
                padding: 0.75rem 2rem;
                .layout-submenu-toggler {
                  display: block;
                }
              }
            }
          }
        }
      }

      &.layout-reveal-anchored {
        .app-logo {
          .layout-sidebar-anchor {
            background-color: var(--logo-color);
          }
        }

        .layout-content-wrapper {
          margin-left: 16rem;
        }
      }
    }
  }
}

